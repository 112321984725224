import { api } from '../lib/axios'

interface GetCtosInTransmissorQuery {
  transmissorId: string
  slot: string
  pon: string
}

export interface GetCtosInTransmissorResponse {
  ctos: {
    id: string
    description: string
  }[]
}

export async function getCtosInTransmissor({
  transmissorId,
  slot,
  pon,
}: GetCtosInTransmissorQuery) {
  const response = await api.get<GetCtosInTransmissorResponse>(
    '/transmissors/cto',
    {
      params: {
        transmissorId,
        slot,
        pon,
      },
    },
  )

  return response.data
}
