import * as Select from '@radix-ui/react-select'
import React, { forwardRef, ReactNode } from 'react'

export interface RootProps extends React.ComponentProps<typeof Select.Root> {
  children: ReactNode
}

export const Root = forwardRef<HTMLDivElement, RootProps>((props) => {
  return <Select.Root {...props} />
})

Root.displayName = 'Select'
